import React from "react";

export default function Equipe() {
    return (
        <section className="py-16 px-8 bg-gray-200 text-center">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Missão */}
                <div className="p-4 bg-black shadow-lg rounded">
                    <h2 className="text-xl font-bold text-[#C28840]">Missão</h2>
                    <p className="text-[#C28840]">Promover ascensão financeira para os clientes com o lucrativo mercado de leilão de imóveis.</p>
                </div>
                
                {/* Valores */}
                <div className="p-4 bg-black shadow-lg rounded">
                    <h2 className="text-xl font-bold text-[#C28840]">Valores</h2>
                    <p className="text-[#C28840]">Nossa assessoria jurídica é pautada pela ética e respeito ao cliente.</p>
                </div>
                
                {/* Visão */}
                <div className="p-4 bg-black shadow-lg rounded">
                    <h2 className="text-xl font-bold text-[#C28840]">Visão</h2>
                    <p className="text-[#C28840]">Auxiliar cada vez mais clientes por todo país, trazendo segurança em suas arrematações de imóveis de leilão.</p>
                </div>
            </div>
        </section>
    );
}
