// ./src/App.js
import React from "react";
import { Helmet } from "react-helmet-async";
import BlocoInicial from "./components/BlocoInicial";
import SobreNos from "./components/SobreNos";
import Servicos from "./components/Servicos";
import Missao from "./components/Missao";
import Contato from "./components/Contato";
import backgroundImage from "./img/Fundo.jpg"; // Imagem de fundo para o bloco inicial
import logo from "./img/Logo.png"; // Logo da empresa
import FAQ from "./components/FAQ";

function App() {
  return (
    <div>
      <Helmet>
        <title>Carol Marques Leilões - Assessoria em Leilão Imobiliário</title>
        <meta
          name="description"
          content="Serviços de assessoria jurídica especializada em leilões imobiliários, oferecendo apoio completo desde a pesquisa até o pós-leilão."
        />
        <meta name="keywords" content="leilão de imóveis, assessoria jurídica, arrematação, segurança jurídica" />
        <meta name="author" content="Carol Marques Leilões" />
        <link rel="canonical" href="https://carolmarquesleiloes.com.br" />
        <meta property="og:title" content="Carol Marques Leilões - Assessoria Completa" />
        <meta property="og:description" content="Serviços de assessoria jurídica especializada em leilões imobiliários." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://carolmarquesleiloes.com.br" />
        <meta property="og:image" content="https://carolmarquesleiloes.com.br/img/Carol1.jpg" />
        <meta property="og:see_also" content="https://www.instagram.com/carolmarquesleilao" />
      </Helmet>

      <BlocoInicial backgroundImage={backgroundImage} logo={logo} />
      <Servicos />
      <SobreNos />
      <Missao />
      <FAQ />
      <Contato />
    </div>
  );
}

export default App;
