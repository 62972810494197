import React from "react";

export default function BlocoInicial({ backgroundImage, logo }) {
    return (
        <div
            className="relative h-screen bg-cover bg-center"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="absolute inset-0 bg-black opacity-50"></div> {/* Camada de escurecimento */}
            <div className="absolute top-8 left-1/2 transform -translate-x-1/2 z-10">
                {logo && <img src={logo} alt="Logo" className="w-38 h-auto" />} {/* Aumentando o logo em 20% */}
            </div>
            <div className="absolute bottom-4 right-4 text-gray-400 text-sm z-10">
                Foto de Wylkon Cardoso
            </div>
        </div>
    );
}
