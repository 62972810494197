import React from "react";

export default function SobreNos() {
    return (
        <section className="py-16 px-8 bg-gray-100 text-center">
            <h2 className="text-4xl font-bold mb-8 text-gray-900">Carolina Marques</h2>
            <div className="flex flex-col md:flex-row items-center md:items-start max-w-4xl mx-auto gap-8">
                {/* Div para a imagem */}
                <div className="md:w-1/3 flex justify-center md:justify-end mb-8 md:mb-0">
                    <img
                        src="/img/Carol1.jpg" // Substitua por sua URL de imagem
                        alt="Carolina Marques"
                        className="w-80 h-96 rounded-lg shadow-xl transition-transform transform hover:scale-105"
                        style={{ width: "303px", height: "457px" }}
                    />
                </div>
                {/* Div para o texto */}
                <div className="md:w-2/3 text-left flex flex-col justify-center">
                    <p className="text-lg text-gray-700 text-justify mb-4">
                        Mulher, advogada e investidora. Com o objetivo de prosperar também a vida das pessoas, criou
                        a Marques Leilões, tendo como foco a escala patrimonial através da arrematação
                        de imóveis de leilão.
                    </p>
                    <p className="text-lg text-gray-700 text-justify mb-4">
                        Formada em Direito pela Universidade Federal Rural do Rio de Janeiro no ano de 2014.
                        Advogada especialista em leilão de imóveis, com dez anos de experiência em arrematações
                        de imóveis de leilões judiciais e extrajudiciais.
                    </p>
                    <p className="text-lg text-gray-700 text-justify mb-4">
                        Iniciou sua jornada no ramo dos leilões em 2014 com arrematações próprias.
                    </p>
                    <p className="text-lg text-gray-700 text-justify mb-4">
                        Inicialmente, atuava com o propósito de arrematar para moradia própria, evoluindo cada
                        vez mais ao vender com lucro para comprar o próximo imóvel ainda mais valorizado.
                    </p>
                    <p className="text-lg text-gray-700 text-justify mb-4">
                        A partir de então, após mais de dez arrematações próprias, passou a assessorar parentes
                        e amigos e ao notar o avanço e prosperidade com os leilões, decidiu assessorar clientes
                        para também prosperarem suas vidas através dos leilões de imóveis.
                    </p>
                    <p className="text-lg text-gray-700 text-justify">
                        O Marques Leilões foi criado com o intuito de realizar alavancagem patrimonial do
                        investidor com segurança e previsibilidade.
                    </p>
                </div>
            </div>
        </section>
    );
}