import React, { useState } from "react";

export default function FAQ() {
  // Estado para controlar a visibilidade das respostas
  const [activeIndex, setActiveIndex] = useState(null);

  // Função para alternar a visibilidade das respostas
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-16 bg-gray-100 px-8">
      <h2 className="text-3xl font-bold text-center mb-8">DÚVIDAS FREQUENTES</h2>

      <div className="max-w-3xl mx-auto">
        {/* Pergunta 1 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(1)}
          >
            POR QUE O IMÓVEL VAI A LEILÃO?
          </div>
          {activeIndex === 1 && (
            <div className="p-4 text-gray-700 border-t">
              Quando um devedor não efetua pagamento de dívida, pode ter seu imóvel levado à leilão. Na maioria dos casos de leilão judicial, os imóveis são levados à leilão para quitação de dívida de condomínio, por exemplo. 
Já no caso do Leilão extrajudicial, o devedor deixa de pagar as parcelas do financiamento do imóvel e o próprio Banco realiza o leilão.

            </div>
          )}
        </div>

        {/* Pergunta 2 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(2)}
          >
            COMO É FEITA A DESOCUPAÇÃO DO IMÓVEL?
          </div>
          {activeIndex === 2 && (
            <div className="p-4 text-gray-700 border-t">
              A desocupação do imóvel, na maioria dos casos, se dá através de acordo extrajudicial, realizando a saída de forma amigável. Quando o acordo não é possível, é necessário ingressar com ação de imissão na posse, em que os juízes costumam conceder o prazo de 60 dias para saída do ocupante.

            </div>
          )}
        </div>

        {/* Pergunta 3 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(3)}
          >
            COMO SABER SE VOU HERDAR DÍVIDAS?
          </div>
          {activeIndex === 3 && (
            <div className="p-4 text-gray-700 border-t">
              O Edital traz todas as regras do jogo, e esclarecerá acerca de eventual assunção de dívidas ou não.


            </div>
          )}
        </div>

        {/* Pergunta 4 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(4)}
          >
            COMO IDENTIFICAR SE O LEILOEIRO É OFICIAL?
          </div>
          {activeIndex === 4 && (
            <div className="p-4 text-gray-700 border-t">
              O leiloeiro oficial é registrado na Junta Comercial do Estado, assim você pode fazer a consulta para verificar sua autenticidade.

            </div>
          )}
        </div>

        {/* Pergunta 5 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(5)}
          >
            O PAGAMENTO É SEMPRE À VISTA?
          </div>
          {activeIndex === 5 && (
            <div className="p-4 text-gray-700 border-t">
              Não, em diversos leilões são permitidos os lances parcelados e/ ou financiados. A regra do jogo sempre estará explícita no edital.

            </div>
          )}
        </div>

        {/* Pergunta 6 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(6)}
          >
            É POSSÍVEL ARREMATAR UM IMÓVEL DE FORMA COLETIVA?
          </div>
          {activeIndex === 6 && (
            <div className="p-4 text-gray-700 border-t">
              É totalmente possível arrematar o imóvel de forma coletiva sendo uma solução coerente para aquele que não dispõe do capital total para arrematação.

            </div>
          )}
        </div>

        {/* Pergunta 7 */}
        <div className="mb-4 bg-white shadow-lg rounded">
          <div
            className="cursor-pointer p-4 bg-black text-[#C28840] font-semibold rounded-t"
            onClick={() => toggleAnswer(7)}
          >
            COMO UMA ASSESORIA JURÍDICA ESPECIALIZADA PODE AJUDAR?
          </div>
          {activeIndex === 7 && (
            <div className="p-4 text-gray-700 border-t">
              Inicialmente, é realizada a seleção dos melhores imóveis nos sites dos leiloeiros conforme as
              premissas dos clientes. Após, seguimos com análise mercadológica, realizando uma pesquisa
              aprofundada para verificar se o valor de avaliação está em acordo com o praticado no mercado. 
              Após, passaremos à análise técnica do edital e processos para que a arrematação seja feita com
              a maior previsibilidade possível.
              Com isso, realizamos a viabilidade financeira com elaboração de planilha com todos os gastos
              previstos, seja com comissão de Leiloeiro, Assessoria, ITBI, custas cartorárias, imposto de
              renda e possíveis reformas, estimando valor aproximado de lucro líquido após período de doze
              meses.
              Por fim, concluímos com o acompanhamento do leilão e suporte no pós leilão, com atuação na
              área administrativa e judicial, visando garantir a desocupação do imóvel, correto recolhimento
              do ITBI, baixa dos gravames, ônus da matrícula e todos desembaraços para registro em
              nome do cliente.
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
