import React from "react";
import { FaSearch, FaChartLine, FaFileAlt, FaCalculator, FaUserCheck, FaHandshake } from "react-icons/fa";

export default function Servicos() {
    return (
        <section className="py-16 px-8 bg-gray-200 text-center">
            <h2 className="text-3xl font-bold mb-4 text-gray-900">Assessoria Jurídica Completa</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-4 bg-black shadow-lg rounded">
                    <h3 className="text-xl font-semibold text-[#C28840] flex items-center justify-center">
                        Pesquisa do Imóvel <FaSearch className="ml-2 text-white" />
                    </h3>
                    <p className="text-[#C28840]">
                        Pesquisa nos sites dos leiloeiros cadastrados nas Juntas para busca das melhores oportunidades de imóveis.
                    </p>
                </div>
                <div className="p-4 bg-black shadow-lg rounded">
                    <h3 className="text-xl font-semibold text-[#C28840] flex items-center justify-center">
                        Estudo Mercadológico <FaChartLine className="ml-2 text-white" />
                    </h3>
                    <p className="text-[#C28840]">
                        Estudo mercadológico do imóvel garimpado: nessa fase, realizamos pesquisa de liquidez e viabilidade financeira do
                        imóvel, considerando toda questão jurídica sobre o leilão.
                    </p>
                </div>
                <div className="p-4 bg-black shadow-lg rounded">
                    <h3 className="text-xl font-semibold text-[#C28840] flex items-center justify-center">
                        Estudo Técnico <FaFileAlt className="ml-2 text-white" />
                    </h3>
                    <p className="text-[#C28840]">
                        Estudo técnico: análise profunda dos editais, processos, intimações, possíveis nulidades, concurso de credores,
                        englobando todo o estudo, visando minimização dos riscos.
                    </p>
                </div>
                <div className="p-4 bg-black shadow-lg rounded">
                    <h3 className="text-xl font-semibold text-[#C28840] flex items-center justify-center">
                        Viabilidade Financeira <FaCalculator className="ml-2 text-white" />
                    </h3>
                    <p className="text-[#C28840]">
                        Estudo financeiro: elaboração de planilha com todos os gastos previstos, seja com comissão de Leiloeiro,
                        Assessoria, ITBI, custas cartorárias, imposto de renda e possíveis reformas, estimando valor aproximado de lucro líquido após período de doze meses.
                    </p>
                </div>
                <div className="p-4 bg-black shadow-lg rounded">
                    <h3 className="text-xl font-semibold text-[#C28840] flex items-center justify-center">
                        Habilitação no Leiloeiro <FaUserCheck className="ml-2 text-white" />
                    </h3>
                    <p className="text-[#C28840]">
                        Validação para habilitação no site dos leiloeiros, bem como todo o suporte na data do leilão.
                    </p>
                </div>
                <div className="p-4 bg-black shadow-lg rounded">
                    <h3 className="text-xl font-semibold text-[#C28840] flex items-center justify-center">
                        Pós-Leilão <FaHandshake className="ml-2 text-white" />
                    </h3>
                    <p className="text-[#C28840]">
                        Suporte no pós arrematação com atuação na área administrativa e judicial, visando garantir a desocupação do imóvel,
                        correto recolhimento do ITBI, baixa dos gravames, ônus da matrícula e todos desembaraços para registro em nome do cliente.
                    </p>
                </div>
            </div>
        </section>
    );
}
