import React from "react";
import { FaWhatsapp, FaEnvelope, FaInstagram } from "react-icons/fa";

export default function Contato() {
    return (
        <section className="py-16 px-8 bg-gray-200 text-center">
            <h2 className="text-3xl font-bold mb-4 text-gray-900">Fale Conosco</h2>
            <p className="text-lg text-gray-700 mb-8">Entre em contato através das nossas Redes Sociais ou E-mail.</p>
            <div className="flex flex-col items-center space-y-8 max-w-4xl mx-auto">
                {/* Box de Redes Sociais */}
                <div className="w-full bg-white p-6 rounded-lg shadow-lg space-y-6 flex flex-col items-center justify-center">
                    

                    {/* Link do WhatsApp */}
                    <div className="flex items-center space-x-4">
                        <a
                            href="https://wa.me/5512981533206" // Substitua com o número de telefone no formato desejado
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center space-x-2 text-lg text-green-500 hover:underline"
                        >
                            <FaWhatsapp className="text-2xl" />
                            <span>WhatsApp</span>
                        </a>
                    </div>

                    {/* Link do Instagram */}
                    <div className="flex items-center space-x-4">
                        <a
                            href="https://www.instagram.com/carolmarquesleilao"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center space-x-2 text-lg text-pink-500 hover:underline"
                        >
                            <FaInstagram className="text-2xl" />
                            <span>@carolmarquesleilao</span>
                        </a>
                    </div>

                    {/* Link de E-mail com mailto */}
                    <div className="flex items-center space-x-4">
                        <a
                            href="mailto:carolmarquesleiloes@gmail.com"
                            className="flex items-center space-x-2 text-lg text-blue-500 hover:underline"
                        >
                            <FaEnvelope className="text-2xl" />
                            <span>carolmarquesleiloes@gmail.com</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
